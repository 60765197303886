/* COLOR */
$danger: #dc3545;
$dark: #231f20;
$light: #fafafa;

/* GRID */
$grid-breakpoints: (
  xs: 0,
  xx: 420px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$grid-gutter-width: 24px;

/* FONT */
$enable-responsive-font-sizes: true;

$font-family-base: "Open Sans", sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.5;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: 700;

$body-color: #565656;

$headings-color: #909090;
$headings-font-family: "Russo One", sans-serif;
$headings-font-weight: 400;

$h1-font-size: $font-size-base * 2.25;
$h2-font-size: $font-size-base * 1.875;
$h3-font-size: $font-size-base * 1.6;
$h4-font-size: $font-size-base * 1.4;
$h5-font-size: $font-size-base * 1.2;
$h6-font-size: $font-size-base * 1;

b,
strong {
  font-weight: 700;
}

/* LINK */
$link-color: $danger;
$link-hover-color: darken($danger, 15%);
$link-hover-decoration: none;

/* NAV */
$navbar-padding-y: 0;
$nav-link-padding-x: .5rem;
$navbar-dark-color: #eee;
$navbar-dark-hover-color: $danger;

/* CARD */
$card-cap-bg: #fff;
