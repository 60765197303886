.fs12 {
  font-size: 12px;
}
.fs14 {
  font-size: 14px;
}
.fs15 {
  font-size: 15px;
}
.fs16 {
  font-size: 16px;
}
.fs18 {
  font-size: 18px;
}
.fs19 {
  font-size: 19px;
}
.fs20 {
  font-size: 20px;
}
.fs21 {
  font-size: 21px;
}
.fs22 {
  font-size: 22px;
}
.fs24 {
  font-size: 24px;
}
.fs26 {
  font-size: 26px;
}
.fs28 {
  font-size: 28px;
}
.fs30 {
  font-size: 30px;
}
.fs32 {
  font-size: 32px;
}
.fs36 {
  font-size: 36px;
}
.fs42 {
  font-size: 42px;
}
.fs48 {
  font-size: 48px;
}
.fs54 {
  font-size: 54px;
}
.fs60 {
  font-size: 60px;
}