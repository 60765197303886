/* VARIABLES */
@import 'variables';

/* BOOTSTRAP */
@import '~bootstrap/scss/bootstrap';

/* PLUGINS */
@import 'plugins/css/fancybox.css';
@import 'plugins/css/fontsize.css';
@import 'plugins/css/svg-icons.css';

/* 
 * COTI STYLE
 */

html {
  scroll-behavior: smooth;
  -webkit-backface-visibility: hidden;
}

body {
  padding-top: 64px;
  background: #f2142b;
}

main.container {
  box-shadow: 0 4px 8px #666;
}

.txt-gray {
  color: #666;
}
.descriptif p {
  margin-bottom: .5rem;
}

/* NAVBAR */
#cotiNav {
  .nav-item {
    font-size: 1.1rem;
    .dropdown-item {
      font-size: 15px;
    }
  }
  @media (min-width: 992px) {
    .dropdown-menu {
      margin-top: -5px;
    }
    .dropdown-item:hover {
      background: $danger;
      color: $white;
    }
    .dropdown:hover > .dropdown-menu {
      display: block;
    }
  }
}

/* CAROUSEL */
.carousel {
  box-shadow: 0 3px 6px rgba(0,0,0,.4);

  .carousel-item img {
    max-height: 410px;
  }

  .carousel-control-prev,
  .carousel-control-prev:hover,
  .carousel-control-prev:focus,
  .carousel-control-next,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    background-image: none;
  }

  .carousel-control-prev {
    justify-content: flex-start;
    padding-left: .75rem;
  }

  .carousel-control-next {
    justify-content: flex-end;
    padding-right: .75rem;
  }

  .carousel-caption {
    bottom: .5rem;
    padding: 0;
    .coti-caption {
      background-color: #0006;
    }
  }
}

/* CARD */
.card {
  .card-header {
    padding: .5rem .5rem 0;
    background-color: white;
    border-bottom: 0;
  }
  
  .card-body {
   padding: .5rem; 
  } 
  
  .card-footer {
    padding: 0 .5rem .5rem;
    background-color: white;
    border-top: 0;
  }
  
  .card-cotimg {
    margin: -1px;
    position: relative;
    height: 100%;
    min-height: 300px;
    max-height: 300px;
    border-radius: 0;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media (min-width: 576px) {
      min-height: 190px;
      max-height: 190px;
    }
    @media (min-width: 768px) {
      min-height: 165px;
      max-height: 165px;
    }
    @media (min-width: 992px) {
      min-height: 170px;
      max-height: 170px;
    }
    @media (min-width: 1200px) {
      min-height: 205px;
      max-height: 205px;
    }
  }

  .img-hover-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 110%;
    width: 100%;
    height: 0;
    padding-top: 0;
    background: #0006;
    text-align: center;
    transition: all 400ms;
    z-index: 2;
  }
  &:hover .img-hover-content {
    top: 0;
    height: 100%;
    color: #fff;
  }
}

.card-shadow:hover {
  box-shadow: 0 2px 6px #999;
}

/* FOOTER */
footer {
  color: #eee;
  li>a {
    display: block;
  }
  a:hover {
    color: white;
  }
}

/* GO TOP */
#cotiTop {
  position: fixed;
  bottom: 12px;
  right: 12px;
  display: none;
  transition: all 400ms ease-in-out;
  z-index: 1050;
  svg {
    filter: drop-shadow(0 2px 4px rgb(0 0 0 / 0.5));
  }
}

/* DIVERS */
.creambg {
  background-image: url('/images/patterns/cream-pixels.png');
}